(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('edistradadhluiApp')
        .constant('VERSION', "132.0.0-3-g1c546c58")
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('API_URL', "https://dhl.pl.qas.edistrada.pl/edistrada2/")
;
})();
